import React from "react"

function ThankYou() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center text-center">
      <svg
        width="200"
        height="200"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
          fill="#48BB78"
        />
      </svg>
      <p className="text-4xl">Thanks!</p>
      <p className="text-lg px-6">
        Just to let you know, we started a discord channel so you can find a
        buddy <strong>right away</strong>.
      </p>

      <p className="text-lg px-6 mt-4">
        Here´s the link:
        <br />
        <a
          href="https://discordapp.com/invite/3YJRR7T"
          className="text-blue-700"
        >
          https://discordapp.com/invite/3YJRR7T
        </a>
      </p>

      <p className="mt-4">Happy Learning :)</p>
      <a href="/" className="text-blue-500 text-xl mt-8">
        Back to home
      </a>
    </div>
  )
}

export default ThankYou
